export function useScrollbarWidth() {
  const [width, setWidth] = React.useState(0)

  React.useEffect(
    () => { setWidth(window.innerWidth - document.body.clientWidth) },
    []
  )

  return width
}
