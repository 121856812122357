export function easeOut(x) {
  return Math.sin((x * Math.PI) / 2)
}

export function easeInExpo(x) {
  return x === 0 ? 0 : Math.pow(2, 10 * x - 10)
}

export function easeInQuart(x) {
  return x * x * x * x
}

export function easeInOutCubic(x) {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
}
