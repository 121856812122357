import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { useEvent } from '/machinery/useEvent'

export function usePlayWhenVisible({ elementRef, onNotAllowed }) {
  const { ref, isInViewport } = useIsInViewport()

  const onNotAllowedEvent = useEvent(handleError)

  React.useEffect(
    () => {
      isInViewport
        ? elementRef.current?.play()?.catch(onNotAllowedEvent)
        : elementRef.current?.pause()
    },
    [elementRef, isInViewport, onNotAllowedEvent]
  )

  return { ref }

  function handleError(/** @type {DOMException} */ e) {
    // This event fires on e.g. low power mode on iOS Safari:
    if (e.name === 'NotAllowedError') {
      onNotAllowed(true)
    }
  }
}
