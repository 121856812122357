import { useMergeRefs } from '@floating-ui/react'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { useMediaQuery } from '@kaliber/use-media-query'

import { useVideoQualityLevel } from '/machinery/useVideoQualityLevel'
import { useFullscreenVideo } from '/machinery/useFullscreenVideo'
import { useTranslate } from '/machinery/I18n'
import { usePlayWhenVisible } from '/machinery/usePlayWhenVisible'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

import { ImageCover } from './Image'

import mediaStyles from '/cssGlobal/media.css'
import styles from './BackgroundVideo.css'

export function BackgroundVideoWithFullscreenToggle({ sources, onCanPlay = undefined, onLoaded = undefined, fallbackImage = undefined, layoutClassName = undefined }) {
  const { ref, isFullscreen, toggle } = useFullscreenVideo()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const { __ } = useTranslate()

  return (
    <div {...{ ref }} className={cx(styles.componentWithFullscreenToggle, layoutClassName)}>
      {isViewportMd && (
        <button
          onClick={handleToggle}
          aria-label={__`fullscreen-button`}
          data-x='click-to-toggle-fullscreen-video'
          className={cx(styles.fullscreenButton, isFullscreen && styles.isFullscreen)}
        />
      )}

      <BackgroundVideoBase
        className={styles.videoLayout}
        {...{ sources, onCanPlay, onLoaded, isFullscreen, fallbackImage }}
      />
    </div>
  )

  function handleToggle() {
    toggle()
    pushToDataLayer({ event: 'interaction', action: 'fullscreen', type: 'Fullscreen video' })
  }
}

function BackgroundVideoBase({ sources, onCanPlay, onLoaded, className, fallbackImage, isFullscreen = undefined }) {
  const [suspended, setSuspended] = React.useState(false)
  const videoRef = React.useRef(null)

  const { ref } = usePlayWhenVisible({
    onNotAllowed: setSuspended,
    elementRef: videoRef,
  })

  const { ref: visibilityRef, isInViewport } = useIsInViewport()
  const { src, ref: qualityRef } = useVideoQualityLevel({
    sources: sources
      ? {
        1080: sources.quality1080,
        720: sources.quality720,
        540: sources.quality540,
        360: sources.quality360,
      }
      : {}
  })

  const refs = useMergeRefs([videoRef, visibilityRef, ref, qualityRef])

  React.useEffect(
    () => {
      if (isInViewport) videoRef.current.play()?.catch(() => {})
      else videoRef.current.pause()
    },
    [src, isInViewport]
  )

  return (
    <div className={cx(styles.componentBase, suspended && styles.isSuspended, className)}>
      <video
        loop
        autoPlay
        playsInline
        muted={!isFullscreen}
        disablePictureInPicture
        preload="auto"
        controlsList="nodownload"
        controls={isFullscreen}
        onLoadedData={onLoaded}
        ref={refs}
        className={cx(
          styles.video,
          suspended && styles.isSuspended
        )}
        {...{ src, onCanPlay }}
      />
      {fallbackImage && (
        <ImageCover
          image={fallbackImage}
          aspectRatio={3 / 4}
          layoutClassName={styles.fallbackImageLayout}
        />
      )}
    </div>
  )
}
