import { useSpring, animated } from 'react-spring'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './CursorPlayButton.css'

import iconPlay from '/images/icons/play.raw.svg'
import { useEvent } from '/machinery/useEvent'

export function CursorPlayButton({ animation = undefined, layoutClassName = undefined }) {
  const Base = animation ? animated.div : 'div'

  return (
    <Base className={cx(styles.component, layoutClassName)} style={animation}>
      <Icon icon={iconPlay} layoutClassName={styles.iconLayout} />
    </Base>
  )
}

const CURSOR_CENTER_OFFSET = 35

export function useCursorProps() {
  const elementRef = React.useRef(null)
  const [isHovering, setIsHovering] = React.useState(false)

  const onHoverEvent = useEvent(setIsHovering)

  const [animation, api] = useSpring(() => ({
    config: key => key === 'opacity' ? { mass: 1, tension: 700, friction: 50 } : {},
    immediate: key => key !== 'opacity',
    opacity: isHovering ? 1 : 0,
    x: null,
    y: null,
  }), [isHovering])

  React.useEffect(
    () => {
      window.addEventListener('mousemove', handleMouseMove)

      return () => window.removeEventListener('mousemove', handleMouseMove)

      function handleMouseMove(e) {
        api.start({
          x: e.clientX + CURSOR_CENTER_OFFSET - 80,
          y: e.clientY + window.scrollY + CURSOR_CENTER_OFFSET - 80
        })
      }
    },
    []
  )

  return { elementRef, animation, setIsHovering: onHoverEvent }
}
