import { animated, useTrail, useSprings } from 'react-spring'
import { determineDocumentPathSyncWithParams } from '/machinery/determineDocumentPathSyncWithParams'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useEvent } from '/machinery/useEvent'
import { useParallax } from '/machinery/useParallax'
import { useBoundingClientRect } from '/machinery/useBoundingClientRect'
import { useScrollbarWidth } from '/machinery/useScrollbarWidth'
import { routeMap } from '/routeMap'

import { UppercaseHeadingXl, UppercaseHeadingXlTitleSubTitle } from '/features/buildingBlocks/UppercaseHeading'
import { ContainerLg, ContainerXl, ContainerSm } from '/features/buildingBlocks/Container'
import { Button } from '/features/buildingBlocks/Button'
import { Image, ImageCropped, ImageCoverDynamicScale } from '/features/buildingBlocks/Image'
import { HeadingXs } from '/features//buildingBlocks/Heading'
import { BackgroundVideoWithFullscreenToggle } from '/features/buildingBlocks/BackgroundVideo'
import { CursorPlayButton, useCursorProps } from '/features/buildingBlocks/CursorPlayButton'
import { Video } from '/features/buildingBlocks/Video'
import { refToDataXLink } from '/machinery/tracking/pushToDataLayer'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Hero.css'

export function HeroTypeSwitcherButtonRef({ hero: originalHero, layoutClassName = undefined }) {
  const hero = {
    ...originalHero,
    button: {
      label: originalHero.button?.label,
      url: refToUrl(originalHero.button),
      dataX: refToDataXLink(originalHero.button?.ref)
    }
  }

  return <HeroTypeSwitcher {...{ hero, layoutClassName }} />
}

export function HeroTypeSwitcher({ hero, layoutClassName = undefined }) {
  const { type, heading, image, video, introduction, collage, button } = hero

  return (
    <HeroBase
      {...{ button, layoutClassName }}
      className={cx(styles.componentTypeSwitcher, styles[`${type}Type`])}
      renderHeading={({ animation }) => <UppercaseHeadingXl h='1' value={heading} {...{ animation }} />}
      renderContent={({ animation, layoutClassName }) =>
        type === 'heroImage' ? <ImageComponent {...{ image, animation, layoutClassName }} /> :
        type === 'heroIntroduction' ? <IntroductionComponent {...{ introduction, animation, layoutClassName }} /> :
        type === 'heroCollage' ? <CollageComponent {...{ collage, animation, layoutClassName }} /> :
        type === 'heroVideo' ? <VideoComponent {...{ video, animation, layoutClassName }} /> :
        null
      }
    />
  )
}

export function HeroTitleSubTitleImage({ title, subtitle, button, image }) {
  return (
    <HeroBase
      {...{ button }}
      className={cx(styles.componentTitleSubTitleImage, styles['heroImageType'])}
      renderHeading={({ animation }) =>
        <UppercaseHeadingXlTitleSubTitle h='1' {...{ title, subtitle, animation }} />
      }
      renderContent={({ animation, layoutClassName }) =>
        <ImageComponent {...{ image, animation, layoutClassName }} />
      }
    />
  )
}

export function HeroVideo({ layoutClassName = undefined, image, video, children, onComponentReady }) {
  const { elementRef, animation, setIsHovering } = useCursorProps()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const hasVideos = video?.urls

  const onMouseMoveEvent = useEvent(handleMouseMove)
  const onMouseLeaveEvent = useEvent(handleMouseLeave)

  useCallConditionallyOnce({ condition: () => !hasVideos, callback: onComponentReady })

  return (
    <div className={cx(styles.componentVideo, layoutClassName)}>
      {isViewportMd && (
        <CursorPlayButton layoutClassName={styles.cursorPlayButtonLayout} {...{ animation }} />
      )}

      {children}

      {image && (
        <ImageCoverDynamicScale
          aspectRatio={1 / 1}
          layoutClassName={styles.imageLayout}
          imgProps={{ className: styles.dynamicImage }}
          {...{ image }}
        />
      )}

      {hasVideos && (
        <div
          ref={elementRef}
          onMouseMove={onMouseMoveEvent}
          onMouseLeave={onMouseLeaveEvent}
          className={styles.backgroundVideoContainer}
        >
          <BackgroundVideoWithFullscreenToggle sources={video.urls} onLoaded={onComponentReady} fallbackImage={video?.image} />
        </div>
      )}
    </div>
  )

  function handleMouseMove() {
    setIsHovering(true)
  }

  function handleMouseLeave() {
    setIsHovering(false)
  }
}

export function useAnimations() {
  const [animations, api] = useTrail(
    4,
    i => ({
      from: { opacity: 0.1, y: 15 },
      to: { opacity: 1, y: 0 },
      delay: i * 2000,
      pause: true,
    }),
    []
  )

  return { animations, startAnimating() { api.start({ pause: false }) } }
}

function HeroBase({
  renderHeading,
  renderContent,
  button = undefined,
  className = undefined,
  layoutClassName = undefined
}) {
  const [animations] = useSprings(3, i => ({
    from: { opacity: 0.1, y: i * 10 },
    to: { opacity: 1, y: 0 },
  }))

  const [headingAnimation, buttonAnimation, contentAnimation] = animations

  return (
    <div className={cx(styles.componentBase, className, layoutClassName)}>
      <ContainerLg layoutClassName={styles.containerLayout}>
        {renderHeading({ animation: headingAnimation })}
      </ContainerLg>

      {button?.url && button?.label && (
        <ButtonHero
          url={button.url}
          dataX={button.dataX}
          label={button.label}
          animation={buttonAnimation}
          layoutClassName={styles.buttonLayout}
        />
      )}

      {renderContent({ animation: contentAnimation, layoutClassName: styles.contentLayout })}
    </div>
  )
}

function ButtonHero({ url, dataX, label, layoutClassName, animation }) {
  return (
    <Button href={url} {...{ layoutClassName, animation, dataX }}>
      {label}
    </Button>
  )
}

function IntroductionComponent({ introduction, animation, layoutClassName }) {
  return (
    <ContainerSm {...{ animation, layoutClassName }}>
      <div className={styles.componentIntroductionComponent}>
        <HeadingXs h='3' title={introduction} />
      </div>
    </ContainerSm>
  )
}

function VideoComponent({ video, animation, layoutClassName }) {
  const scrollbarWidth = useScrollbarWidth()
  const { poster, title, url } = video

  return (
    <ContainerXl {...{ animation, layoutClassName }}>
      <div
        style={{ '--scrollbar-width': `${scrollbarWidth ?? 15}px` }}
        className={cx(styles.componentVideoComponent, styles['heroVideoType'])}
      >
        <Video layoutClassName={styles.videoLayout} {...{ poster, title, url }} />
      </div>
    </ContainerXl>
  )
}

function ImageComponent({ image, animation, layoutClassName }) {
  return (
    <ContainerXl {...{ animation, layoutClassName }}>
      <ImageCropped
        aspectRatio={16 / 9}
        imgProps={{ fetchpriority: 'high' }}
        {... { image }}
      />
    </ContainerXl>
  )
}

function CollageComponent({ collage, animation, layoutClassName }) {
  const { elementRef, width, height } = useBoundingClientRect()
  const { ref, style: { value } } = useParallax()

  const [firstImage, ...restImages] = collage?.images ?? []

  return (
    <ContainerLg {...{ animation, layoutClassName }}>
      <div
        style={{ '--height': `${height ?? 0}px` }}
        className={styles.componentCollageComponent}
        {...{ ref }}
      >
        {firstImage && (
          <div className={styles.firstImageContainer} ref={elementRef}>
            <CollageImage
              image={firstImage}
              {...{ value, width }}
            />
          </div>
        )}

        {restImages.map((image, i) => (
          <CollageImage
            key={image._key}
            animationIndex={i + 1}
            {...{ image, value, width }}
          />
        ))}
      </div>
    </ContainerLg>
  )
}

function CollageImage({ animationIndex = 0, image, value, width }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  const multiplier = isViewportMd ? 1.1 : 0.5
  const height = isViewportMd ? 20 : 35

  const isMainImage = animationIndex === 0

  const y = value.to(x => {
    const desktopOffset = 1.1 - animationIndex * (animationIndex * 0.1)
    const mobileOffset = 1.25 - (animationIndex * 0.3)
    const exponential = x * x

    const offset = `var(--height) / ${isViewportMd ? desktopOffset : mobileOffset}`
    const calculation = `-${(exponential) * animationIndex * multiplier * height}svh`

    return `calc((${offset}) + (${calculation}))`
  })

  const scale = value.to(x => {
    const lowerBound = 0.9 - animationIndex * 0.1
    const offset = 0.1 + (0.1 * animationIndex) * x

    return lowerBound + offset
  })

  const filter = value.to(x => {
    const distance = `0 0 ${animationIndex * 15}px`
    const color = `rgba(0, 0, 0, ${animationIndex * x * 0.05})`

    return `drop-shadow(${distance} ${color})`
  })

  return (
    <animated.div style={{ height: 'max-content', y: isMainImage ? 0 : y, scale, filter }}>
      <Image sizes={width ?? 0} imgProps={{ fetchpriority: 'high' }} {...{ image }} />
    </animated.div>
  )
}

function refToUrl(button) {
  const { ref, params } = button ?? {}
  return ref && determineDocumentPathSyncWithParams({ document: ref, routeMap, params })
}

function useCallConditionallyOnce({ condition, callback }) {
  const conditionEvent = useEvent(condition)
  const callbackEvent = useEvent(callback)

  React.useEffect(
    () => {
      if (conditionEvent()) callbackEvent()
    },
    [conditionEvent, callbackEvent]
  )
}
