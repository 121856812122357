import { useScrollProgression, triggers } from '@kaliber/scroll-progression'
import { easeOut } from '/machinery/easings'
import { useSpring } from 'react-spring'
import { lerp } from '@kaliber/math'

export function useParallax() {
  const [style, api] = useSpring(() => ({
    value: 0,
  }))

  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.bottom(), scrollParent: triggers.top() },
    onChange: x => api.start({ value: lerp({ start: 0, end: 1, input: easeOut(x) }) })
  })

  return { ref, style }
}

export function useParallaxText({ start = 100, end = 0 } = {}) {
  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.bottom(), scrollParent: triggers.bottom() },
    onChange: x => api.start({
      value: easeOut(x),
      y: lerp({ start, end, input: easeOut(x) })
    })
  })

  const [style, api] = useSpring(() => ({
    value: 0,
    y: start,
    config: { tension: 500, friction: 35 }
  }))

  return { ref, style }
}

export function useParallaxBackgroundImage({ offset = 200 } = {}) {
  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.bottom(), scrollParent: triggers.center(offset) },
    onChange: x => api.start({ y: lerp({ start: -offset, end: 0, input: easeOut(x) }) })
  })

  const [style, api] = useSpring(() => ({
    y: offset,
    x: '50%',
    config: { tension: 500, friction: 35 }
  }))

  return { ref, style }
}
