import screenfull from 'screenfull'
import { useEvent } from '/machinery/useEvent'

export function useFullscreenVideo({ onToggle = undefined } = {}) {
  const elementRef = React.useRef(null)
  const [isFullscreen, setIsFullscreen] = React.useState(false)

  const onToggleEvent = useEvent(handleFullScreen)

  React.useEffect(() => {
    if (screenfull.isEnabled) screenfull.on('change', handleFullscreenChange)

    return () => screenfull.off('change', handleFullscreenChange)

    function handleFullscreenChange() {
      setIsFullscreen(screenfull.isFullscreen)
    }
  }, [])

  return {
    ref: elementRef,
    toggle: onToggleEvent,
    isFullscreen,
  }

  function handleFullScreen() {
    screenfull.toggle(elementRef.current)
    onToggle && onToggle(screenfull.isEnabled)
  }
}
