import { animated } from 'react-spring'

import styles from './Container.css'

export function ContainerSm({ children, layoutClassName = undefined, animation = undefined }) {
  return <ContainerBase className={styles.componentSm} {...{ children, layoutClassName, animation }} />
}

export function ContainerMd({ children, layoutClassName = undefined }) {
  return <ContainerBase className={styles.componentMd} {...{ children, layoutClassName }} />
}

export function ContainerLg({ children, layoutClassName = undefined, animation = undefined }) {
  return <ContainerBase className={styles.componentLg} {...{ children, layoutClassName, animation }} />
}

export function ContainerXl({ children, layoutClassName = undefined, animation = undefined }) {
  return <ContainerBase className={styles.componentXl} {...{ children, layoutClassName, animation }} />
}

function ContainerBase({ children, className, layoutClassName, animation = undefined }) {
  const ElementBase = animation ? animated.div : 'div'

  return (
    <ElementBase className={cx(styles.componentBase, className, layoutClassName)} style={animation}>
      <div className={styles.layout}>
        {children}
      </div>
    </ElementBase>
  )
}
